// extracted by mini-css-extract-plugin
export var mainContainer = "CourseTakeNavBar-module--mainContainer--Wimz3";
export var mainDiv = "CourseTakeNavBar-module--mainDiv--5_eVB";
export var topMainDiv = "CourseTakeNavBar-module--topMainDiv--WCrR3";
export var logoDiv = "CourseTakeNavBar-module--logoDiv--1yJxb";
export var logoImg = "CourseTakeNavBar-module--logoImg--1fBem";
export var leftDiv = "CourseTakeNavBar-module--leftDiv--2yApj";
export var rightDiv = "CourseTakeNavBar-module--rightDiv--3RMHc";
export var arrowLeft = "CourseTakeNavBar-module--arrowLeft--3agiu";
export var backButtonDiv = "CourseTakeNavBar-module--backButtonDiv--19s6_";
export var leftDesktopDiv = "CourseTakeNavBar-module--leftDesktopDiv--2Bph6";
export var leftMobileDiv = "CourseTakeNavBar-module--leftMobileDiv--2SWLS";
export var burgerIconSvg = "CourseTakeNavBar-module--burgerIconSvg--3ilMi";
export var burgerIconPath = "CourseTakeNavBar-module--burgerIconPath--1GfhA";
export var burgerCloseButtonDiv = "CourseTakeNavBar-module--burgerCloseButtonDiv--3NsD3";
export var burgerCloseButtonSvg = "CourseTakeNavBar-module--burgerCloseButtonSvg--20pKv";
export var burgerCloseButtonPath = "CourseTakeNavBar-module--burgerCloseButtonPath--1dGfQ";