import { mainContainer, mainDiv, logoDiv, logoImg, topMainDiv, leftDiv, rightDiv, arrowLeft,
  backButtonDiv, leftDesktopDiv, leftMobileDiv, burgerIconSvg, burgerIconPath, burgerCloseButtonDiv,
  burgerCloseButtonSvg, burgerCloseButtonPath} from './CourseTakeNavBar.module.css'
import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby';
import userContext from "../../utils/userContext"

const CourseTakeNavBar = ({showLeftBarCallbackSwitch, burgerMenuOpened, courseId, courseData}) => {

  function openBurgerMenu() {
    showLeftBarCallbackSwitch();
  }

  function closeBurgerMenu() {
    showLeftBarCallbackSwitch();
  }

  function goBack() {
    if (userContext.isSuscribedToCourse(courseId)) {
      navigate('/');
    } else {
      navigate('/landing/' + courseData.pageUrl);
    }
  }

  return (
    <nav className={mainContainer}>
      <div className={mainDiv}>
        <div className={topMainDiv}>
          <div className={leftDiv}>
            <div className={leftDesktopDiv}>
              <i className={arrowLeft}></i>
              <div className={backButtonDiv} onClick={() => goBack()}>
                Volver
              </div>
            </div>
            <div className={leftMobileDiv}>
              { burgerMenuOpened ?
                <div className={burgerCloseButtonDiv} onClick={() => closeBurgerMenu()}>
                  <svg className={burgerCloseButtonSvg} width="24px" height="24px" viewBox="0 0 24 24"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path className={burgerCloseButtonPath} d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
                </div>
                :
                <svg className={burgerIconSvg} onClick={() => openBurgerMenu()} width="24px" height="24px" viewBox="0 0 48 48">
                  <path className={burgerIconPath} d="M7.94977 11.9498H39.9498" stroke-width="4" stroke-linecap="round"/>
                  <path className={burgerIconPath} d="M7.94977 23.9498H39.9498" stroke-width="4" stroke-linecap="round"/>
                  <path className={burgerIconPath} d="M7.94977 35.9498H39.9498" stroke-width="4" stroke-linecap="round"/>
                </svg>
              }
            </div>
          </div>
          <div className={logoDiv}>
            <a href="/" aria-label="Home">
              <StaticImage className={logoImg} src="../images/LogoHorizontal_2.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
            </a>
          </div>
          <div className={rightDiv}>
            
          </div>
        </div>
      </div>
    </nav>
  )
}

export default CourseTakeNavBar
