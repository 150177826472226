import { outerDiv, mainDiv, innerDiv, leftBarDiv, courseChapterDiv, courseChapterNameDiv, courseChapterSectionsCountDiv, 
  arrowUp, arrowDown, courseChapterSectionsArrowDiv, leftBarInnerDiv, rightBarDiv, courseChapterMainDiv,
  courseContentChapterSectionMainDiv, courseContentChapterSectionIconSvg, courseContentChapterSectionIconPath,
  courseContentChapterSectionNameDiv, courseContentChapterSectionsMainDiv, courseContentChapterSectionSecondLineDiv, 
  courseChapterOpenedDiv, courseChapterClosedDiv, courseContentChapterSectionSecondLineTypeDiv, sectionNameSelected,
  sectionNameNotSelected, sectionMainSelected, sectionMainNotSelected, rightBarTitleDiv, innerRightBarDiv,
  rightBarContentDiv, rightBarButtonsDiv, rightBarNoCompleteButton, rightBarCompleteButton, rightBarContinueButton,
  buttonNextArrow, sectionCircleSvg, sectionCirclePath, leftCompleteDiv, completeLineOuter, completeLineInner,
  completeTextPercentageDiv, completeTextTextDiv, completeTextDiv, completeTitleDiv, leftBarOpenedDiv, leftBarClosedDiv,
  goBackLeftButtonDiv, rightBarTextContentDiv, rightBarExpandCompressDiv, vimeoIframe } from './{mysqlAllCourses.pageUrl}.module.css'
import React, { useState, useEffect } from "react"
import CourseTakeNavBar from "../../components/courses/CourseTakeNavBar"
import SectionNotFreeModal from "../../components/courses/SectionNotFreeModal"
import fbTrackUtils from "../../utils/fbTrackUtils"
import { graphql, navigate } from 'gatsby'
import { Helmet } from "react-helmet"
import userContext from "../../utils/userContext"
import useWindowSize from "../../utils/useWindowSize";

const CoursePage = ({ data, location }) => {
  const params = new URLSearchParams(location.search);
  const ps = params.get('ps');

  useEffect(() => {
    if (ps) {
      fbTrackUtils.trackPurchase();
    }
  }, [])
  
  const courseData = data.allMysqlAllCourses.nodes[0];
  const courseId = data.allMysqlAllCourses.nodes[0].id.substring(data.allMysqlAllCourses.nodes[0].id.lastIndexOf("__") + 2);

  const [ contentsOpened, setContentsOpened ] = React.useState(getInitialContentsOpened());
  const [ selectedSection, setSelectedSection ] = React.useState(null);
  const [ sectionsCompleted, setSectionsCompleted ] = React.useState(null);
  const [ completePercentage, setCompletePercentage ] = React.useState(0);
  const [ sectionsCompletedCountByChapter, setSectionsCompletedCountByChapter ] = React.useState(getInitialSectionsCompletedCountByChapter());
  const [ burgerMenuOpened, setBurgerMenuOpened] = React.useState(true);
  const [ showSectionNotFreeModal, setShowSectionNotFreeModal] = React.useState(false);
  const { width } = useWindowSize();

  function getInitialContentsOpened() {
    var contentsOpenedTmp = [true];
    for (var i = 1; i < courseData.courseChapters.length; i++) {
        contentsOpenedTmp[i] = false;
    }

    return contentsOpenedTmp;
  }
  
  function getInitialSectionsCompletedCountByChapter() {
    var sectionsCompletedCountByChapterTmp = [];
    for (var i = 0; i < courseData.courseChapters.length; i++) {
      sectionsCompletedCountByChapterTmp[i] = 0;
    }

    return sectionsCompletedCountByChapterTmp;
  }

  function openContent(index) {
    setContentsOpened(contentsOpened => {
        return contentsOpened.map((item, j) => {
          return j === index ? !item : item
        })
    })
  }

  function selectSection(item) {
    if (!canAccessSection(item)) {
      setShowSectionNotFreeModal(true);
      return;
    }
    setSelectedSection(item);
    if (width < 1200) {
      setBurgerMenuOpened(false);
    }
  }

  function markSectionCompleted(item) {
    if (!userContext.user.courses || !userContext.user.courses[courseId] || !userContext.user.courses[courseId].sectionsCompleted) {
      userContext.user.courses[courseId] = {sectionsCompleted: {}};
    }
    userContext.user.courses[courseId].sectionsCompleted[getSectionId(item)] = true;
    userContext.user.lastCourse = courseData.pageUrl;
    userContext.user.lastCourseId = courseId;
    setSectionsCompleted({
      ...sectionsCompleted,
      [getSectionId(item)]: true,
    })
    updateCompletePercentage();
    updateSectionsCompletedCountByChapter();

    userContext.updateUser();

    goToNextSection();
  }

  function markSectionUncompleted(item) {
    userContext.user.courses[courseId].sectionsCompleted[getSectionId(item)] = false;
    userContext.user.lastCourse = courseData.pageUrl;
    userContext.user.lastCourseId = courseId;
    setSectionsCompleted({
      ...sectionsCompleted,
      [getSectionId(item)]: false,
    })
    updateCompletePercentage();
    updateSectionsCompletedCountByChapter();

    userContext.updateUser();
  }

  function goToNextSection() {
    var sectionToSelect = null;

    var end = false;
    for (var i = 0; i < courseData.courseChapters.length; i++) {
      var currentChapter = courseData.courseChapters[i];
      for (var j = 0; j < currentChapter.courseSections.length; j++) {
        var currentSection = currentChapter.courseSections[j];
        if (selectedSection.id == currentSection.id) {
          if (j + 1 >= currentChapter.courseSections.length) {
            if (i + 1 >= courseData.courseChapters.length) {
              end = true;
              break;
            } else {
              openContent(i + 1);
              sectionToSelect = courseData.courseChapters[i + 1].courseSections[0];
            }
          } else {
            sectionToSelect = currentChapter.courseSections[j + 1];
          }
          end = true;
          break;
        }
      }
      if (end) {
        break;
      }
    }

    if (!canAccessSection(sectionToSelect)) {
      setShowSectionNotFreeModal(true);
      return;
    }
    setSelectedSection(sectionToSelect);
  }

  function getSectionId(section) {
    return section.id.substring(section.id.lastIndexOf("__") + 2);
  }

  function updateCompletePercentage() {
    var totalSectionsCount = 0;
    var sectionsCompletedCount = 0;
    for (var i = 0; i < courseData.courseChapters.length; i++) {
      var currentChapter = courseData.courseChapters[i];
      totalSectionsCount += currentChapter.courseSections.length;

      for (var j = 0; j < currentChapter.courseSections.length; j++) {
        var currentSection = currentChapter.courseSections[j];

        if (userContext.user.courses[courseId] && 
            userContext.user.courses[courseId].sectionsCompleted && 
            userContext.user.courses[courseId].sectionsCompleted[getSectionId(currentSection)]) {
          sectionsCompletedCount++;
        }
      }
    }

    setCompletePercentage(Math.floor(sectionsCompletedCount*100/totalSectionsCount));
  }

  function updateSectionsCompletedCountByChapter() {
    for (var i = 0; i < courseData.courseChapters.length; i++) {
      var currentChapter = courseData.courseChapters[i];
      var currentCount = 0;

      for (var j = 0; j < currentChapter.courseSections.length; j++) {
        var currentSection = currentChapter.courseSections[j];
         
        if (userContext.user.courses[courseId] && 
            userContext.user.courses[courseId].sectionsCompleted &&
            userContext.user.courses[courseId].sectionsCompleted[getSectionId(currentSection)]) {
          currentCount++;
        }
      }

      sectionsCompletedCountByChapter[i] = currentCount;
      setSectionsCompletedCountByChapter({
        ...sectionsCompletedCountByChapter,
        [i]: currentCount,
      })
    }
  }

  function showLeftBarCallbackSwitch() {
    setBurgerMenuOpened(!burgerMenuOpened);
  }

  function closeSectionNotFreeModal() {
    setShowSectionNotFreeModal(false);
  }

  function canAccessSection(section) {
    if (section.isFree) {
      return true;
    }
    return userContext.isSuscribedToCourse(courseId);
  }

  function setChapterOpened(chapterIndex) {
    var contentsOpenedTmp = [];
    for (var i = 0; i < courseData.courseChapters.length; i++) {
        contentsOpenedTmp[i] = chapterIndex == i;
    }

    setContentsOpened(contentsOpenedTmp);
  }

  function updateSelectedSection() {
    for (var i = 0; i < courseData.courseChapters.length; i++) {
      var currentChapter = courseData.courseChapters[i];

      for (var j = 0; j < currentChapter.courseSections.length; j++) {
        var currentSection = currentChapter.courseSections[j];

        if (!userContext.user.courses[courseId] || 
            !userContext.user.courses[courseId].sectionsCompleted ||
            !userContext.user.courses[courseId].sectionsCompleted[getSectionId(currentSection)]) {
          setSelectedSection(currentSection);
          setChapterOpened(i);
          return;
        }
      }
    }
  }

  useEffect(() => {
    if (!userContext.isLogged()) {
      navigate('/crear-cuenta/');
    } else {
      userContext.loadUser().then(resultStatus => {
        if (resultStatus != "OK") {
          navigate('/login');
        } else {
          setSectionsCompleted(userContext.user.courses[courseId].sectionsCompleted);
          updateCompletePercentage();
          updateSectionsCompletedCountByChapter();
          updateSelectedSection();
        }
      });
    }
  }, [])

  return (
    <div className={outerDiv}>
      <div className={mainDiv}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{courseData.name} - Fede Cocina</title>
            <meta name="description" content={courseData.name}/>
            <html lang="es"/>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
          </Helmet>
        <CourseTakeNavBar showLeftBarCallbackSwitch={showLeftBarCallbackSwitch} burgerMenuOpened={burgerMenuOpened} courseId={courseId} courseData={courseData}></CourseTakeNavBar>
        <div className={innerDiv}>
          <nav className={[leftBarDiv, burgerMenuOpened ? leftBarOpenedDiv : leftBarClosedDiv].join(" ")}>
            <div className={leftBarInnerDiv}>
              <div className={leftCompleteDiv}>
                <div className={goBackLeftButtonDiv} onClick={() => navigate('/')}>
                  &lt; VOLVER
                </div>
                <div className={completeTitleDiv}>
                  {courseData.name}
                </div>
                <div className={completeLineOuter}>
                  <div className={completeLineInner} style={{width: completePercentage + '%'}}></div>
                </div>
                <div className={completeTextDiv}>
                  <div className={completeTextPercentageDiv}>
                    {completePercentage}%
                  </div>
                  <div className={completeTextTextDiv}>
                    Completado
                  </div>
                </div>
              </div>
            { courseData.courseChapters.map((itemChapter, indexChapter) => {
                return (
                  <div className={courseChapterMainDiv}>
                    <div className={[courseChapterDiv, contentsOpened[indexChapter] ? courseChapterOpenedDiv : courseChapterClosedDiv].join(" ")} onClick={() => openContent(indexChapter)}>
                      <div className={courseChapterNameDiv}>
                        {'Capítulo ' + (indexChapter + 1) + ' - ' + itemChapter.name}
                      </div>
                      <div className={courseChapterSectionsCountDiv}>
                        {sectionsCompletedCountByChapter[indexChapter]}/{itemChapter.courseSections.length}
                      </div>
                      <div className={courseChapterSectionsArrowDiv}>
                          <i className={contentsOpened[indexChapter] ? arrowUp : arrowDown}></i>
                      </div>
                    </div>
                    { contentsOpened[indexChapter] ?
                      <div className={courseContentChapterSectionsMainDiv}>
                          {itemChapter.courseSections.map((itemSection) => {
                              return (
                                  <div className={[courseContentChapterSectionMainDiv, selectedSection == itemSection ? sectionMainSelected : sectionMainNotSelected].join(" ")} onClick={() => selectSection(itemSection)}>
                                      <svg className={sectionCircleSvg} width="16px" height="16px" viewBox="0 0 24 24" fill="none">
                                        { sectionsCompleted && sectionsCompleted[getSectionId(itemSection)] ? 
                                          <path className={sectionCirclePath} d="M8 12.5L11 15.5L16 9.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                          :
                                          <></>
                                        }
                                        <circle className={sectionCirclePath} cx="12" cy="12" r="10" stroke-width="1"/>
                                      </svg>
                                      <div className={[courseContentChapterSectionNameDiv, selectedSection == itemSection ? sectionNameSelected : sectionNameNotSelected].join(" ")}>
                                          {itemSection.name}
                                      </div>
                                      <div className={courseContentChapterSectionSecondLineDiv}>
                                        {itemSection.icon == 'video' ?
                                            <>
                                              <svg className={courseContentChapterSectionIconSvg} width="16px" height="16px" viewBox="0 0 28 28">
                                                  <path className={courseContentChapterSectionIconPath} d="M5.25 5.5C3.45507 5.5 2 6.95508 2 8.75V19.25C2 21.0449 3.45507 22.5 5.25 22.5H15.7523C17.5472 22.5 19.0023 21.0449 19.0023 19.25V17.6707L23.5434 20.7824C24.3729 21.3508 25.4999 20.7568 25.4999 19.7512V8.24842C25.4999 7.24298 24.3732 6.64898 23.5436 7.21708L19.0023 10.3272V8.75C19.0023 6.95508 17.5472 5.5 15.7523 5.5H5.25ZM19.0023 15.8524V12.1452L23.9999 8.72263V19.2769L19.0023 15.8524ZM17.5023 8.75V19.25C17.5023 20.2165 16.7187 21 15.7523 21H5.25C4.2835 21 3.5 20.2165 3.5 19.25V8.75C3.5 7.7835 4.2835 7 5.25 7H15.7523C16.7188 7 17.5023 7.7835 17.5023 8.75Z" fill="#212121"/>
                                              </svg>
                                              <div className={courseContentChapterSectionSecondLineTypeDiv}>
                                                Video - {itemSection.durationMins} min {itemSection.isFree ? ' - Sección Gratuita' : ''}
                                              </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {itemSection.icon == 'text' ?
                                            <>
                                              <svg className={courseContentChapterSectionIconSvg} width="16px" height="16px" viewBox="0 0 1024 1024">
                                                  <path className={courseContentChapterSectionIconPath} d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 01-32 32H160a32 32 0 01-32-32V96a32 32 0 0132-32zm160 448h384v64H320v-64zm0-192h160v64H320v-64zm0 384h384v64H320v-64z"/>
                                              </svg>
                                              <div className={courseContentChapterSectionSecondLineTypeDiv}>
                                                Texto {itemSection.isFree ? ' - Sección Gratuita' : ''}
                                              </div>
                                            </>
                                            :
                                            <></>
                                        }
                                      </div>
                                  </div>
                              )
                          })}
                      </div>
                      :
                      <></>
                  }
                </div>
                );
              })
            }
            </div>
          </nav>
          <div className={rightBarDiv}>
            <div className={innerRightBarDiv}>
              {showSectionNotFreeModal}
              {showSectionNotFreeModal ? 
                <SectionNotFreeModal closeModalCallback={closeSectionNotFreeModal} courseData={courseData}></SectionNotFreeModal>
                :
                <></>
              }
              {selectedSection ? 
                <>
                  <div className={rightBarTitleDiv}>
                    <div className={rightBarExpandCompressDiv}>
                      { burgerMenuOpened ?
                        <svg width="16" height="16" viewBox="0 0 352.054 352.054" onClick={() => setBurgerMenuOpened(false)}>
                          <polygon points="144.206,186.634 30,300.84 30,238.059 0,238.059 0,352.054 113.995,352.054 113.995,322.054 51.212,322.054 165.419,207.847"/>
                          <polygon points="238.059,0 238.059,30 300.84,30 186.633,144.208 207.846,165.42 322.054,51.213 322.054,113.995 352.054,113.995 352.054,0"/>
                        </svg>
                        :
                        <svg width="16" height="16" viewBox="0 0 352.054 352.054" onClick={() => setBurgerMenuOpened(true)}>
                          <polygon points="339.411,21.213 318.198,0 226.274,91.924 176.022,41.672 176.022,163.596 297.946,163.596 247.487,113.137"/>
	                        <polygon points="163.595,176.022 41.672,176.022 91.924,226.275 0,318.198 21.213,339.411 113.137,247.488 163.596,297.947"/>
                        </svg>
                      }
                    </div>
                    {selectedSection.name}
                  </div>
                  <div className={rightBarContentDiv}>
                    {selectedSection.videoUrl ?
                      <>
                        <iframe className={vimeoIframe} src={selectedSection.videoUrl + '?color=334980&autoplay=true'} width="640" height="390" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      </>
                      :
                      <div className={rightBarTextContentDiv} dangerouslySetInnerHTML={{__html: selectedSection.textContent}}></div>
                    }
                  </div>
                  <div className={rightBarButtonsDiv}>
                    { sectionsCompleted && sectionsCompleted[getSectionId(selectedSection)] ? 
                      <>
                        <button className={rightBarNoCompleteButton} onClick={() => markSectionUncompleted(selectedSection)}>Dejar como no completado</button>
                        <button className={rightBarContinueButton} onClick={() => goToNextSection()}>
                          Continuar
                          <svg className={buttonNextArrow} width="16px" height="16px" viewBox="0 0 485 485">
                            <path d="M271.2,125.5c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l84.4,84.4h-323C6,229,0,235,0,242.5S6,256,13.5,256h323	l-84.4,84.4c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.5,0,6.9-1.3,9.5-4L378.6,252c2.5-2.5,4-6,4-9.5s-1.4-7-4-9.5	L271.2,125.5z"/>
                          </svg>
                        </button> 
                      </>
                    :
                      <button className={rightBarCompleteButton} onClick={() => markSectionCompleted(selectedSection)}>
                        Completar y continuar
                        <svg className={buttonNextArrow} width="16px" height="16px" viewBox="0 0 485 485">
                            <path d="M271.2,125.5c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l84.4,84.4h-323C6,229,0,235,0,242.5S6,256,13.5,256h323	l-84.4,84.4c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.5,0,6.9-1.3,9.5-4L378.6,252c2.5-2.5,4-6,4-9.5s-1.4-7-4-9.5	L271.2,125.5z"/>
                        </svg>
                      </button>
                    }
                  </div>
                </>
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query($pageUrl: String) {
    allMysqlAllCourses(filter: {pageUrl: {eq: $pageUrl}}) {
      nodes {
        id
        name
        pageUrl
        canBuy
        courseChapters {
          name
          courseSections {
            id
            name
            isFree
            icon
            durationMins
            textContent
            videoUrl
          }
        }
        coursePrices {
          price
          coursePriceCountries {
            cod_country
            coin_symbol
            coin_cod
          }
        }
      }
    }
  }
`

export default CoursePage
