// extracted by mini-css-extract-plugin
export var interestedModalBackgroundDiv = "SectionNotFreeModal-module--interestedModalBackgroundDiv--22dru";
export var interestedModalMainDiv = "SectionNotFreeModal-module--interestedModalMainDiv--1S4vn";
export var interestedModalInnerDiv = "SectionNotFreeModal-module--interestedModalInnerDiv--SLhOZ";
export var buyButtonDiv = "SectionNotFreeModal-module--buyButtonDiv--1__aX";
export var buyButton = "SectionNotFreeModal-module--buyButton--a2sy4";
export var interestedModalTitle = "SectionNotFreeModal-module--interestedModalTitle--1leTo";
export var interestedModalText = "SectionNotFreeModal-module--interestedModalText--1bD83";
export var interestedModalCloseButtonDiv = "SectionNotFreeModal-module--interestedModalCloseButtonDiv--3izvL";
export var interestedModalCloseButtonSvg = "SectionNotFreeModal-module--interestedModalCloseButtonSvg--2VwKL";
export var interestedModalCloseButtonPath = "SectionNotFreeModal-module--interestedModalCloseButtonPath--1_cKK";