import { interestedModalBackgroundDiv, interestedModalMainDiv, interestedModalInnerDiv, buyButtonDiv, buyButton,
         interestedModalTitle, interestedModalText, interestedModalCloseButtonDiv, interestedModalCloseButtonSvg,
         interestedModalCloseButtonPath} from './SectionNotFreeModal.module.css'
import React, { useState } from "react"
import Loading from "../../components/Loading"
import pricesContext  from "../../utils/pricesContext";
import userContext from "../../utils/userContext"
import buyUtils from "../../utils/buyUtils"

const SectionNotFreeModal = ({closeModalCallback, courseData}) => {

    const [ loading, setLoading ] = React.useState(false);

    function buy() {
        if (courseData.canBuy) {
            setLoading(true);
            buyUtils.buy(userContext);
        }
    }

    function closeModal() {
        closeModalCallback();
    }

    return (
        <div className={interestedModalBackgroundDiv}>
            { loading ? <Loading></Loading> : <></> }
            <div className={interestedModalMainDiv}>
                <div className={interestedModalInnerDiv}>
                    <div className={interestedModalCloseButtonDiv} onClick={() => closeModal()}>
                        <svg className={interestedModalCloseButtonSvg} width="32px" height="32px" viewBox="0 0 24 24"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path className={interestedModalCloseButtonPath} d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
                    </div>
                    <div className={interestedModalTitle}>
                        Este contenido no está incluido en la previsualización gratuita.
                    </div>
                    <div className={interestedModalText}>
                        Compra el curso para tener acceso ilimitado a todas las clases.
                    </div>
                    <div className={buyButtonDiv}>
                        <button className={buyButton} type="submit" onClick={buy}>{ courseData.canBuy ? 'Comprar ' + pricesContext.getCurrentPriceString(courseData, 1) : 'Me Interesa' }</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionNotFreeModal
